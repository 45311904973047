import React from 'react';

import '../../../styles/component.scss';

import { Route, Switch } from 'react-router-dom';
import Header from '../../components/Header';
import MyTrails from '../MyTrails';
import Evaluation from '../AvaliacaoView';
import DetailsCompany from '../Details/DetailsCompany';
import DetailsTrail from '../Details/DetailsTrail';
import DetailsCourse from '../Details/DetailsCourse';
import SearchCompany from '../Search/SearchCompany';
import SearchTrail from '../Search/SearchTrail';
import HomeProfessional from '../Home';
import LearningUnit from '../LearningUnit';
import PageContainer from '../../../components/PageContainer';
import HeaderMobile from '../../../components/HeaderMobile';
import Profile from '../Profile';
import WatchClass from '../WatchClass';
import ListCoursesTrail from '../ListCoursesTrail';
import { ItensProps } from '../../../interfaces/components';
import { Error404 } from '../../../ErrorPages/404';
import Challenges from '../Challenges';

const links: ItensProps[] = [
  { id: 1, link: '/', nome: 'Home' },
  { id: 3, link: '/search_trail', nome: 'Trilhas' },
  { id: 5, link: '/search_company', nome: 'Empresas' },
];

const options: ItensProps[] = [
  { id: 1, nome: 'Meu Perfil', link: '/profile' },
  { id: 2, nome: 'Minhas Trilhas', link: '/mytrails' },
];

const ContainerProfessional = () => (
  <>
    <PageContainer
      backgroundColor="#005F6B"
      Header={Header}
      HeaderMobile={HeaderMobile}
      color="#A9A9A9"
      options={options}
      links={links}
    >
      <Switch>
        <Route path="/" exact component={HomeProfessional} />
        <Route path="/mytrails" component={MyTrails} />
        <Route path="/search_trail" component={SearchTrail} />
        <Route path="/search_company" component={SearchCompany} />
        <Route path="/details_course/:id" component={DetailsCourse} />
        <Route path="/details_trail/:id" component={DetailsTrail} />
        <Route path="/list_coursesTrail" component={ListCoursesTrail} />
        <Route path="/details_company/:id" component={DetailsCompany} />
        <Route path="/learningUnit/:id" component={LearningUnit} />
        <Route path="/evaluation/:id" component={Evaluation} />
        <Route path="/profile" component={Profile} />
        <Route path="/watchClass/:id" component={WatchClass} />
        <Route path="/desafios" component={Challenges} />
        <Route component={Error404} />
      </Switch>
    </PageContainer>
  </>
);
export default ContainerProfessional;
